.projects-container {
  margin-bottom: 200px;
}

.menu-item {
  position: relative;
  padding: 1.6vw 0 0 0;
}

.menu-item-link {
  display: inline-block;
  cursor: pointer;
  position: relative;
  -webkit-transition: opacity 0.4s;
  transition: opacity 0.4s;
  font-family: 'Aesthetic';
  font-weight: normal;
  text-transform: uppercase;
}

.menu-item-link::before {
  all: initial;
  text-transform: uppercase;
  counter-increment: menu;
  position: absolute;
  bottom: 60%;
  left: 0;
  pointer-events: none;
}

.menu-item-link:hover {
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
  opacity: 0;
}

.menu-item-img {
  pointer-events: none;
  position: absolute;
  height: 50vh;
  max-height: 250px;
  opacity: 0;
  left: 100%;
  top: 50%;
  -webkit-transform: translate3d(calc(-100% - 6vw), -30%, 0)
    translate3d(0, 20px, 0);
  transform: translate3d(calc(-100% - 6vw), -30%, 0) translate3d(0, 20px, 0);
}

.menu-item-link:hover + .menu-item-img {
  opacity: 1;
  -webkit-transform: translate3d(calc(-100% - 6vw), -30%, 0)
    rotate3d(0, 0, 1, 4deg);
  transform: translate3d(calc(-100% - 6vw), -30%, 0) rotate3d(0, 0, 1, 4deg);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.menu {
  padding: 0vh 0 0 0vh;
  --offset: 20vw;
  --move-initial: calc(-25% + var(--offset));
  --move-final: calc(-50% + var(--offset));
}

.marquee {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  font-size: 8vw;
}

.marquee-inner {
  position: relative;
  -webkit-animation: marquee 2s linear infinite;
  animation: marquee 2s linear infinite;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
  opacity: 0;
  -webkit-transition: opacity 0.1s;
  transition: opacity 0.1s;
  padding: 2vw 0;
}

.menu-item-link:hover ~ .marquee .marquee-inner {
  -webkit-animation-play-state: running;
  animation-play-state: running;
  opacity: 1;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
}

.menu-item-link,
.marquee span {
  white-space: nowrap;
  font-size: 7vw;
  padding: 1.6vw 0;
  font-weight: 900;
}

.marquee span {
  font-family: 'Aesthetic' !important;
  font-weight: normal;
  -webkit-text-stroke: 1px var(--primary-color);
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
}


.project-desc {
  font-size: 1em;
  text-transform: uppercase;
  padding-bottom: 0.3em;
}

@-webkit-keyframes marquee {
  0% {
    -webkit-transform: translate3d(var(--move-initial), 0, 0);
    transform: translate3d(var(--move-initial), 0, 0);
  }
  100% {
    -webkit-transform: translate3d(var(--move-final), 0, 0);
    transform: translate3d(var(--move-final), 0, 0);
  }
}

@keyframes marquee {
  0% {
    -webkit-transform: translate3d(var(--move-initial), 0, 0);
    transform: translate3d(var(--move-initial), 0, 0);
  }
  100% {
    -webkit-transform: translate3d(var(--move-final), 0, 0);
    transform: translate3d(var(--move-final), 0, 0);
  }
}

@media (max-width: 900px) {
  .menu-item-img {
    height: 10vh;
    max-height: 150px;
  }
}

@media only screen and (max-width: 600px) {
  .project-desc {
    font-size: 0.3em;
  }
}
