@font-face {
  font-family: 'Aesthetic';
  src: url('../assets/Aesthetic-Regular.woff2') format('woff2'),
    url('../assets/Aesthetic-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* --------COLOR STYLE GUIDE-------- */
:root {
  --bg-color: #f9f4ef;
  --primary-color: #716040;
  --secondary-color: #f25042;
}

/* GLOBAL STYLES */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  cursor: none;
}

body {
  height: 100%;
  isolation: isolate;
  color: var(--primary-color);
  background: var(--bg-color);
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  font-family: neue-haas-unica, sans-serif;
  transition: background 0.3s ease-in-out, color 0.6s ease-in-out;
}

body::before {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  opacity: 0.02;
  z-index: 1500;
  pointer-events: none;
  background: url(../assets/noise.gif);
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

a {
  text-decoration: none;
  color: inherit;
}

h1 {
  font-family: 'Aesthetic';
}

.wrapper {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.wrapper .main-container {
  width: 90%;
  margin: 0 auto;
}

.divider {
  color: var(--bg-color);
  border-bottom: 1.5px solid var(--primary-color);
}

.whitespace {
  width: 100%;
  height: 100px;
}

.main-cursor {
  z-index: 9999;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  border: 1px solid var(--secondary-color);
  pointer-events: none;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  position: fixed;
}

.secondary-cursor {
  z-index: 9999;
  pointer-events: none;
  width: 20px;
  height: 20px;
  transform: translate3d(0, 0, 0);
  position: fixed;
  background-color: var(--secondary-color);
  border-radius: 50%;
}

.section-header .section-header-container {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  font-size: 1rem;
}

.link-item {
  display: inline-block;
  position: relative;
}

.link-item:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1.5px;
  bottom: 0;
  left: 0;
  background: var(--primary-color);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.link-item:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.App {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  pointer-events: none;
}

.scroll {
  padding: 2vh 0;
  pointer-events: none;
}

@media (max-width: 600px) {
  * {
    cursor: default;
  }
  .main-cursor {
    display: none;
  }

  .secondary-cursor {
    display: none;
  }
}
