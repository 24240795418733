.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  width: 100%;
  height: 100vh;
  background: var(--primary-color);
  color: var(--bg-color);
  font-family: 'Aesthetic';
  font-size: 5rem;
}

.name {
  font-size: 3rem;
}