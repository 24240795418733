.header {
  margin: -20px 0;
  z-index: 2 !important;
}

.header .header-container h1 {
  position: relative;
  font-family: 'Aesthetic';
  font-size: 13rem;
  font-weight: normal;
  letter-spacing: -5px;
  margin: 0.4em 0;
}

.header-container p {
  max-width: 850px;
  font-family: neue-haas-unica, sans-serif;
  font-style: normal;
  word-spacing: 5px;
  text-transform: uppercase;
}

.title-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  font-family: 'Aesthetic';
  font-size: 1.8rem;
  text-transform: uppercase;
  margin-bottom: 20px;
  z-index: -2000;
}

.divider-block {
  border-bottom: 50px solid var(--bg-color);
}

.about-container {
  display: flex;
  align-items: center;
  gap: 1em;
}

.avatar-img {
  border-radius: 50%;
  border: 2px solid var(--primary-color);
  width: 180px;
}

@media (max-width: 945px) {
  .header .header-container h1 {
    margin: 1em 0 0 0;
    text-align: center;
    font-size: 7rem;
  }

  .header-container p {
    margin: 0 auto 25px;
    text-align: center;
    font-size: 1rem;
  }

  .title-container {
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 12px;
    font-family: 'Aesthetic';
    font-size: 1.5rem;
    text-transform: uppercase;
  }
}

@media (max-width: 550px) {
  .header .header-container h1 {
    text-align: center;
    font-size: 5rem;
  }

  .header-container p {
    font-size: 0.8rem;
  }

  .title-container {
    font-size: 0.9rem;
  }
}

@media (max-width: 450px) {
  .header .header-container h1 {
    text-align: center;
    font-size: 4rem;
    margin-bottom: -50px;
    margin-top: 1.5em;
  }

  .header-container p {
    font-size: 0.6rem;
    text-align: center;
  }

  .title-container {
    font-size: 0.7rem;
  }
}
