.contact-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.socials {
  cursor: pointer;
  font-size: 1.2rem;
  font-style: normal;
}

@media (max-width: 900px) {
  .socials {
    font-size: 1rem;
  }
}

@media (max-width: 500px) {
  .socials {
    font-size: 0.8rem;
  }
}
