.navbar .navbar-container {
  display: flex;
  padding: 25px 0;
  font-size: 1rem;
  justify-content: flex-end;
  background-color: rgba(254, 254, 254, 0);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(7px);

}

.nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 80px;
  z-index: 1;
}

.nav-links {
  display: flex;
  gap: 10px;
}

.nav-links span {
  color: var(--primary-color);
  /* background: var(--primary-color); */
  border: 1px solid var(--primary-color);
  padding: 5px 12px;
  border-radius: 50px;
  mix-blend-mode: difference;
  z-index: 3000;
}

.nav-links span:hover {
  cursor: pointer;
  background: var(--primary-color);
  color: var(--bg-color);
}



@media (max-width: 840px) {
  .nav {
    right: 0;
  }
  .navbar .navbar-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
  }

  .nav-links {
    gap: 20px;
  }

  .logo {
    display: none;
  }

  .nav-links span {
    font-size: 0.7rem;
  }
}
