.design-container {
  margin-top: 80px;
}

div.flag {
  width: 100vw;
  height: 100vh;
}

.gallery {
  -webkit-column-count: 6;
  -moz-column-count: 6;
  column-count: 6;
  -webkit-column-width: 33%;
  -moz-column-width: 33%;
  column-width: 30%;
  padding: 0 15px;
}

.gallery img {
  width: 100%;
}

.gallery .images {
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  margin-bottom: 10px;
  position: relative;
  width: 100%;
}

.images img {
  border-radius: 2px;
  cursor: pointer;
}

.images img {
  transition: 1s ease;
}

.images img:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  transition: 1s ease;
}

.images:hover img {
  filter: opacity(0.8);
}

.backdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 1;
}

.backdrop .modal-img {
  display: block;
  width: 50%;
  max-width: 50%;
  height: auto;
  max-height: 10%;
  line-height: 0;
  padding: 100px 0 100px;
  margin: 0 auto;
  transition: transform 0.5s;
  cursor: pointer;
  overflow: hidden;
}

.load-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.load-btn {
  padding-top: 20px;
  font-size: 1rem;
  font-style: normal;
  color: var(--primary-color);
  background: none;
  border: none;
  cursor: pointer;
}

@media (max-width: 900px) {
  .gallery {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
  }
}

@media (max-width: 500px) {
  .gallery {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }

}
